import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { graphql, withPrefix } from 'gatsby'
import Layout from '../components/Layout'
import Markdown from 'react-markdown'
import getYouTubeId from '../components/utils/getYoutubeId'
import FeaturedPressRelease from '../components/FeaturedPressRelease'
import ShareNav from '../components/ShareNav'
import PressReleaseFooter from '../components/PressReleaseFooter'

const Blockquote = ({ text, isIntro = false }) => {
    const El = isIntro ? 'p' : 'blockquote'

    return (
        <El
            className={cx(
                'gradient-ocean-berry text-transparent background-clip-text text-28 leading-snug mb-40',
                {
                    'border-l-4 border-gray-100 pl-36': !isIntro,
                    'font-medium': isIntro,
                }
            )}
        >
            {text}
        </El>
    )
}

Blockquote.propTypes = {
    text: PropTypes.string.isRequired,
    isIntro: PropTypes.bool,
}

export const PressReleasePageTemplate = ({
    authorData,
    content,
    date,
    introduction,
    slug,
    summary,
    title,
    linkedPages,
}) => {
    return (
        <div>
            {authorData && (
                <FeaturedPressRelease
                    post={{ authorData, date, summary, title }}
                    isArticleEntry={true}
                />
            )}
            <article
                className={cx(
                    'relative max-w-768 mx-auto my-64 lg:my-90 px-24'
                )}
            >
                <ShareNav
                    shareLink={`${withPrefix('https://beacon.bio')}${slug}`}
                    title={title}
                />
                {introduction && (
                    <Blockquote text={introduction} isIntro={true} />
                )}
                {content &&
                    content.length &&
                    content.map((item, i) => {
                        const id = item.videoUrl && getYouTubeId(item.videoUrl)

                        return (
                            <div key={i} className={cx('my-40')}>
                                {item.blockquote && (
                                    <Blockquote text={item.blockquote} />
                                )}

                                {id && (
                                    <div
                                        className={cx(
                                            'aspect-ratio-16/9 relative w-full my-40'
                                        )}
                                    >
                                        <iframe
                                            src={`https://www.youtube-nocookie.com/embed/${id}?rel=0&modestbranding=1`}
                                            tabIndex="0"
                                            frameBorder="0"
                                            title="YouTube Video"
                                            allow="accelerometer;
                                            autoplay;
                                            encrypted-media;
                                            gyroscope;
                                            picture-in-picture"
                                            allowFullScreen
                                            className={cx(
                                                'absolute w-full h-full'
                                            )}
                                        />
                                    </div>
                                )}

                                {item.body && (
                                    <Markdown
                                        source={item.body}
                                        className="rich-text"
                                    />
                                )}
                            </div>
                        )
                    })}
            </article>
            <PressReleaseFooter linkedPages={linkedPages} />
        </div>
    )
}

PressReleasePageTemplate.propTypes = {
    authorData: PropTypes.shape({
        authorName: PropTypes.string,
        authorImage: PropTypes.object,
        authorTitle: PropTypes.string,
    }),
    content: PropTypes.array,
    date: PropTypes.string,
    introduction: PropTypes.string,
    slug: PropTypes.string,
    summary: PropTypes.string,
    title: PropTypes.string.isRequired,
    linkedPages: PropTypes.array,
}

const PressReleasePage = ({ data }) => {
    const { frontmatter } = data.post
    const authors = data.authors.edges

    let author = frontmatter.author
    let entryAuthorMatch = authors.find(
        (item) => item.node.frontmatter.authorName === author
    )

    const linkedPages = [
        {
            header: frontmatter.linkedPageOneHeader,
            description: frontmatter.linkedPageOneDescription,
            url: frontmatter.linkedPageOneUrl,
            newTab: frontmatter.linkedPageOneNewTab,
        },
        {
            header: frontmatter.linkedPageTwoHeader,
            description: frontmatter.linkedPageTwoDescription,
            url: frontmatter.linkedPageTwoUrl,
            newTab: frontmatter.linkedPageTwoNewTab,
        },
    ]

    if (entryAuthorMatch)
        frontmatter.authorData = entryAuthorMatch.node.frontmatter

    return (
        <Layout
            title={frontmatter.title}
            description={frontmatter.introduction}
        >
            <PressReleasePageTemplate
                authorData={frontmatter.authorData}
                content={frontmatter.content}
                date={frontmatter.date}
                introduction={frontmatter.introduction}
                slug={data.post.fields.slug}
                summary={frontmatter.summary}
                title={frontmatter.title}
                linkedPages={linkedPages}
            />
        </Layout>
    )
}

export default PressReleasePage

PressReleasePage.propTypes = {
    data: PropTypes.shape({
        post: PropTypes.object,
        posts: PropTypes.object,
        authors: PropTypes.object,
    }),
}

export const pageQuery = graphql`
    query PressReleaseByID($id: String!) {
        post: markdownRemark(id: { eq: $id }) {
            id
            fields {
                slug
            }
            frontmatter {
                title
                author
                date
                summary
                introduction
                linkedPageOneHeader
                linkedPageOneDescription
                linkedPageOneUrl
                linkedPageOneNewTab
                linkedPageTwoHeader
                linkedPageTwoDescription
                linkedPageTwoUrl
                linkedPageTwoNewTab
                content {
                    blockquote
                    body
                    videoUrl
                }
            }
        }
        authors: allMarkdownRemark(
            sort: { fields: frontmatter___date }
            filter: { fileAbsolutePath: { regex: "/(_authors)/" } }
        ) {
            edges {
                node {
                    frontmatter {
                        authorName
                        authorTitle
                        authorImage {
                            childImageSharp {
                                fluid(maxWidth: 100, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
