import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import AuthorCard from './AuthorCard'
import Markdown from 'react-markdown'

export default function FeaturedPressRelease({
    post: { title, authorData, date, summary },
}) {
    return (
        <section
            className={cx(
                'relative flex min-h-400 text-white lg:items-center bg-purple-700'
            )}
        >
            <div
                className={cx(
                    'max-w-1264 mx-auto px-24 md:px-40 xl-px-0 py-80'
                )}
            >
                <div className={cx('block hd-sm mb-40 lg:mb-20')}>
                    Press Release
                </div>
                <div
                    className={cx('lg:grid gap-120 lg:min-w-full', {
                        'lg:grid-cols-1': !summary,
                        'lg:grid-cols-2': !!summary,
                    })}
                >
                    <div>
                        <h1 className={cx('hd-jb md:hd-jbb mb-36')}>{title}</h1>

                        {(authorData || date) && (
                            <AuthorCard
                                authorName={authorData.authorName}
                                authorTitle={authorData.authorTitle}
                                authorImage={authorData.authorImage}
                                date={date}
                            />
                        )}
                    </div>
                    <div>
                        {summary && (
                            <Markdown
                                source={summary}
                                className="rich-text lg-d:mt-40"
                            />
                        )}
                    </div>
                </div>
            </div>
        </section>
    )
}

FeaturedPressRelease.propTypes = {
    post: PropTypes.object,
}
