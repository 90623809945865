import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import ArrowRight from '../img/inline/arrow-right.svg'

export default function PressReleaseFooter({ linkedPages }) {
    return (
        <section
            className={cx(
                'bg-purple-800 text-white mb-60 pt-60 pb-100 px-24 md:px-40 xl-px-0'
            )}
        >
            <div className={cx('grid lg:grid-cols-2 gap-40 mt-60 lg:mt-90')}>
                {linkedPages.map((page) => {
                    return (
                        <div key={page.url}>
                            <article
                                className={cx(
                                    'relative h-full min-h-380 text-white duration-200 z-1 bg-purple-700',
                                    'transform hover:shadow-lg lg:hover:scale-105 lg:focus:scale-105'
                                )}
                            >
                                <div className={cx('p-24 md:p-40 pb-0')}>
                                    <div className="mb-12 font-bold">
                                        {page.header}
                                    </div>
                                    <div>{page.description}</div>
                                </div>
                                <a
                                    href={page.url}
                                    rel="noreferrer"
                                    target={page.newTab ? '_blank' : ''}
                                    className={cx('flex  justify-center')}
                                >
                                    <ArrowRight
                                        style={{
                                            height: '32px',
                                            width: '32px',
                                            borderRadius: '25px',
                                            padding: '5px',
                                            position: 'fixed',
                                            bottom: 24,
                                        }}
                                        className={cx(
                                            'text-purple-700 bg-white'
                                        )}
                                    />
                                </a>
                            </article>
                        </div>
                    )
                })}
            </div>
        </section>
    )
}

PressReleaseFooter.propTypes = {
    linkedPages: PropTypes.array,
}
