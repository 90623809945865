import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import Twitter from '../img/inline/share-twitter.svg'
import LinkedIn from '../img/inline/share-linkedin.svg'
import Facebook from '../img/inline/share-facebook.svg'
import Email from '../img/inline/share-email.svg'

const ShareNav = ({ title, shareLink, className }) => {
    const linkClasses =
        'block text-blue-500 hover:text-green-500 focus:text-green-500 duration-200'

    return (
        <nav
            aria-label="Blog Post Sharing Navigation"
            className={cx(
                'lg:absolute lg:left-0 lg:top-0 lg:transform lg:-translate-x-64 lg-d:mb-24',
                className
            )}
        >
            <p
                className={cx(
                    'text-12 uppercase font-semibold text-gray-700 lg-d:mb-16 lg:text-center'
                )}
            >
                Share
            </p>
            <ul
                className={cx(
                    'flex lg:flex-col items-center lg-d:space-x-16 lg:mt-16 lg:space-y-16'
                )}
            >
                <li>
                    <a
                        href={`https://twitter.com/share?url=${shareLink}&amp;text=${title}&amp;via=biosignals`}
                        className={cx(linkClasses)}
                        data-track-gtm={`Blog Post Share Navigation|Twitter`}
                    >
                        <span className={cx('sr-only')}>Twitter</span>
                        <Twitter className={cx('rect-social-icon-sm')} />
                    </a>
                </li>
                <li>
                    <a
                        href={`https://www.linkedin.com/sharing/share-offsite/?url=${shareLink}`}
                        className={cx(linkClasses)}
                        data-track-gtm={`Blog Post Share|LinkedIn`}
                    >
                        <span className={cx('sr-only')}>LinkedIn</span>
                        <LinkedIn className={cx('rect-social-icon-sm')} />
                    </a>
                </li>
                <li>
                    <a
                        href={`https://www.facebook.com/sharer/sharer.php?u=${shareLink}`}
                        className={cx(linkClasses)}
                        data-track-gtm={`Blog Post Share|Facebook`}
                    >
                        <span className={cx('sr-only')}>Facebook</span>
                        <Facebook className={cx('rect-social-icon-sm')} />
                    </a>
                </li>
                <li>
                    <a
                        href={`mailto:?subject=${title}&body=${title}: ${shareLink}`}
                        className={cx(linkClasses)}
                        data-track-gtm="Blog Post Share|Email"
                    >
                        <span className={cx('sr-only')}>Email</span>
                        <Email className={cx('rect-social-icon-sm')} />
                    </a>
                </li>
            </ul>
        </nav>
    )
}

ShareNav.propTypes = {
    title: PropTypes.string.isRequired,
    shareLink: PropTypes.string.isRequired,
    className: PropTypes.string,
}

export default ShareNav
